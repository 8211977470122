const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://vwwtxiczpe.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_API_URL: 'https://9nlsj2i0ad.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://nycrtszsrd.execute-api.eu-west-1.amazonaws.com/prod'
  },
};

export default config;